import React from 'react';
import Button from '../components/Button';
import Breadcrumb from '../components/Breadcrumb';

function Pricing() {
  const pricingTiers = [
    {
      name: "Starter",
      price: "499",
      description: "Perfect for small businesses just getting started with social media",
      features: [
        "10 social media posts per month",
        "2 revisions per post",
        "Basic analytics",
        "72-hour delivery",
      ]
    },
    {
      name: "Professional",
      price: "999",
      description: "Ideal for growing businesses with active social presence",
      features: [
        "25 social media posts per month",
        "Unlimited revisions",
        "Advanced analytics",
        "48-hour delivery",
        "Custom branding",
      ]
    },
    {
      name: "Enterprise",
      price: "Custom",
      description: "For businesses needing a comprehensive solution",
      features: [
        "Unlimited social media posts",
        "Priority revisions",
        "Real-time analytics",
        "24-hour delivery",
        "Dedicated account manager",
        "Custom strategy sessions",
      ]
    }
  ];

  return (
    <div className="pt-[96px]">
      <div className="max-w-[1100px] mx-auto px-24 py-48">
        <Breadcrumb />
        
        <h1 className="text-6xl md:text-6xl text-4xl font-medium leading-tight tracking-[-4px] mb-16 mt-32 text-center">
          Simple, Transparent Pricing
        </h1>
        <p className="text-lg md:text-xl font-light text-secondary tracking-[-0.5px] mb-48 text-center max-w-2xl mx-auto px-16">
          Choose the plan that best fits your needs. All plans include access to our creative team and fast turnaround times.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-24 mb-64">
          {pricingTiers.map((tier, index) => (
            <div key={tier.name} className="bg-white rounded-lg p-24 md:p-32 shadow-lg border border-gray-100">
              <h3 className="text-xl md:text-2xl font-medium mb-8">{tier.name}</h3>
              <div className="mb-16">
                <span className="text-3xl md:text-4xl font-medium">
                  {tier.price === "Custom" ? tier.price : `$${tier.price}`}
                </span>
                {tier.price !== "Custom" && <span className="text-secondary">/mo</span>}
              </div>
              <p className="text-secondary mb-24 text-sm md:text-base">{tier.description}</p>
              
              <ul className="space-y-12 md:space-y-16 mb-32">
                {tier.features.map((feature, i) => (
                  <li key={i} className="flex items-center gap-8">
                    <svg className="w-16 h-16 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              
              <Button 
                variant={index === 1 ? "primary" : "secondary"}
                className="w-full"
              >
                {tier.price === "Custom" ? "Contact Us" : "Get Started"}
              </Button>
            </div>
          ))}
        </div>

        {/* Bottom CTA */}
        <div className="text-center border-t border-gray-100 pt-48">
          <h2 className="text-3xl font-medium mb-16">Ready to Transform Your Social Media?</h2>
          <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-32 max-w-2xl mx-auto">
            Join thousands of businesses that trust us with their social media content creation.
          </p>
          <div className="flex justify-center gap-16">
            <Button variant="primary">Start Your Free Trial</Button>
            <Button variant="secondary">Schedule a Demo</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing; 