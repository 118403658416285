import React from 'react';
import Breadcrumb from './Breadcrumb';
import Button from './Button';

function SimplePage({ title, content, ctaText, onCtaClick }) {
  return (
    <div className="pt-[96px]">
      <div className="max-w-[1100px] mx-auto px-24 py-48">
        <Breadcrumb />
        
        <div className="mt-32">
          <h1 className="text-6xl font-medium leading-tight tracking-[-4px] mb-32">
            {title}
          </h1>
          
          <div className="prose prose-lg w-full prose-p:text-black prose-p:font-normal">
            {content}
          </div>

          {ctaText && (
            <div className="mt-48">
              <Button variant="primary" onClick={onCtaClick}>
                {ctaText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SimplePage; 