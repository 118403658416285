export const brandData = [
  {
    id: 1,
    name: "CRANEL",
    image: "/images/creatives/cra1.jpg",
    logo: "/images/brands/cranel.jpeg"
  },
  {
    id: 2,
    name: "YES YOU CAN",
    image: "/images/creatives/yyc1.gif",
    logo: "/images/brands/yyc.jpeg"
  },
  {
    id: 3,
    name: "Vita Hustle",
    image: "/images/creatives/vh1.png",
    logo: "/images/brands/vitahustle.png"
  },
  // {
  //   id: 4,
  //   name: "Love Fitness Apparel",
  //   image: "/images/creatives/image1.png",
  //   logo: "/images/brands/lfa.png"
  // },
  {
    id: 5,
    name: "Strong Jaw",
    image: "/images/creatives/str2.png",
    logo: "/images/brands/strongjaw.jpg"
  },
  // {
  //   id: 6,
  //   name: "Vita Hustle",
  //   image: "/images/creatives/vh2.png",
  //   logo: "/images/brands/vitahustle.png"
  // },
  // {
  //   id: 7,
  //   name: "Locked",
  //   image: "/images/creatives/image1.png",
  //   logo: "/images/brands/locked.png"
  // },
  {
    id: 8,
    name: "YES YOU CAN",
    image: "/images/creatives/yyc2.jpg",
    logo: "/images/brands/yyc.jpeg"
  },
  {
    id: 9,
    name: "CRANEL",
    image: "/images/creatives/cra2.png",
    logo: "/images/brands/cranel.jpeg"
  },
  {
    id: 10,
    name: "Vita Hustle",
    image: "/images/creatives/vh3.png",
    logo: "/images/brands/vitahustle.png"
  }
]; 
