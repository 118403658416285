import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Button from './Button';
import OrderModal from './OrderModal';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  // const [hoveredLink, setHoveredLink] = useState(null);
  // const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  // const links = [
  //   { to: "/about", label: "About" },
  //   { to: "/pricing", label: "Pricing" },
  //   { to: "/how-it-works", label: "How It Works" },
  //   { to: "/contact", label: "Contact" },
  //   { to: "/faq", label: "FAQ" }
  // ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <header 
        className={`fixed w-full bottom-0 z-50 transition-colors duration-200 bg-black
          ${isScrolled ? 'shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)]' : ''}
        `}
      >
        {/* Left corner SVG */}
        <div className="absolute left-0 bottom-full -translate-y-[-1px]">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M32 32H0V0C0 17.673 14.327 32 32 32Z" fill="black"/>
          </svg>
        </div>

        {/* Right corner SVG */}
        <div className="absolute right-0 bottom-full -translate-y-[-1px]">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M0 32H32V0C32 17.673 17.673 32 0 32Z" fill="black"/>
          </svg>
        </div>

        <div className="max-w-[1100px] mx-auto px-24 py-8 flex items-center justify-between">
          <Link to="/">
            <img 
              src="/images/socialmatelogo.png" 
              alt="SocialMate Logo"
              className="h-[20px] w-auto brightness-0 invert"
            />
          </Link>

          <Button 
            variant="primary"
            onClick={() => setIsOrderModalOpen(true)}
            className="bg-white text-black hover:bg-white/90"
          >
            Book a Call
          </Button>

          {/* Mobile Menu Button - Temporarily Hidden */}
          {/* <button 
            className="md:hidden p-8"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg className="w-24 h-24 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              {isMobileMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button> */}

          {/* Desktop Navigation - Temporarily Hidden */}
          {/* <nav className="hidden md:flex items-center relative">
            <AnimatePresence>
              {hoveredLink !== null && (
                <motion.div
                  layoutId="hoverBackground"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute h-32 bg-gray-800 rounded-full"
                  style={{
                    width: hoveredLink?.width || 0,
                    left: hoveredLink?.left || 0
                  }}
                />
              )}
            </AnimatePresence>

            <div className="flex gap-32">
              {links.map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  className={`relative px-16 py-8 text-white hover:text-white/90 transition-colors
                    ${location.pathname === link.to ? 'text-white' : 'text-white/70'}
                  `}
                  onMouseEnter={(e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    setHoveredLink({
                      width: rect.width,
                      left: e.currentTarget.offsetLeft
                    });
                  }}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </nav> */}

          {/* Mobile Menu - Temporarily Hidden */}
          {/* <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className="absolute bottom-full left-0 right-0 bg-black border-t border-gray-800 shadow-lg"
              >
                <div className="p-24 space-y-16">
                  {links.map((link) => (
                    <Link
                      key={link.to}
                      to={link.to}
                      className={`block py-8 text-lg ${
                        location.pathname === link.to ? 'text-white' : 'text-white/70'
                      }`}
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      {link.label}
                    </Link>
                  ))}
                  <Button 
                    variant="primary" 
                    className="w-full mt-16 bg-white text-black hover:bg-white/90"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Book a Call
                  </Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence> */}
        </div>
      </header>

      <OrderModal 
        isOpen={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(false)}
      />
    </>
  );
}

export default Header;