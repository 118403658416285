import React from 'react';
import SimplePage from '../components/SimplePage';

function Privacy() {
  const content = (
    <>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        Last updated: March 2024
      </p>
      
      <h2 className="text-2xl font-medium mb-16 mt-32">Information Collection</h2>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        We collect information that you provide directly to us, including when you create an account, make a purchase, or communicate with us. This may include your name, email address, payment information, and any other information you choose to provide.
      </p>

      <h2 className="text-2xl font-medium mb-16 mt-32">Use of Information</h2>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        We use the information we collect to provide, maintain, and improve our services, process your transactions, communicate with you, and comply with legal obligations. We do not sell your personal information to third parties.
      </p>

      <h2 className="text-2xl font-medium mb-16 mt-32">Data Security</h2>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        We implement appropriate security measures to protect your personal information. However, no method of transmission over the Internet is 100% secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="text-2xl font-medium mb-16 mt-32">Cookie Policy</h2>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        We use cookies and similar tracking technologies to enhance your experience on our website. You can control cookie settings through your browser preferences.
      </p>
    </>
  );

  return (
    <SimplePage
      title="Privacy Policy"
      content={content}
    />
  );
}

export default Privacy; 