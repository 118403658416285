import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../components/Button';
import Breadcrumb from '../components/Breadcrumb';

function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How does the subscription process work?",
      answer: "Our subscription process is simple. Choose a plan that fits your needs, complete the payment, and we'll reach out within 24 hours to get started. We'll collect your brand guidelines and preferences, then begin creating your social media content according to your chosen plan's schedule."
    },
    {
      question: "What types of content do you create?",
      answer: "We create a wide range of social media content including static posts, carousel posts, stories, and reels. Our content can include product photography, lifestyle shots, graphic design, and typography-based designs. All content is customized to match your brand's style and voice."
    },
    {
      question: "How do revisions work?",
      answer: "Revisions are included in all our plans. Once we deliver content, you can request changes to ensure it perfectly matches your vision. The number of revisions depends on your plan - our Starter plan includes 2 revisions per post, while Professional and Enterprise plans include unlimited revisions."
    },
    {
      question: "What's your typical turnaround time?",
      answer: "Turnaround times vary by plan. Our Starter plan has a 72-hour turnaround, Professional plan offers 48-hour delivery, and Enterprise clients enjoy 24-hour delivery. For urgent requests, please contact us to discuss rush options."
    },
    {
      question: "Can I cancel or change my plan?",
      answer: "Yes, you can cancel or modify your plan at any time. Changes will take effect at the start of your next billing cycle. There are no long-term contracts or cancellation fees."
    },
    {
      question: "Do you work with specific industries only?",
      answer: "We work with businesses across various industries. Our team has experience creating content for e-commerce, B2B, lifestyle brands, tech companies, and more. We adapt our creative approach to suit your industry and target audience."
    },
    {
      question: "What file formats do you deliver?",
      answer: "We deliver content in all standard social media formats. This includes JPG, PNG, and MP4 files optimized for each platform. We can also provide source files upon request for Enterprise clients."
    },
    {
      question: "How do I provide feedback on content?",
      answer: "We use a simple feedback system through our client portal where you can comment directly on content pieces and request revisions. For Enterprise clients, we also offer scheduled feedback calls with your dedicated account manager."
    }
  ];

  return (
    <div className="pt-[96px]">
      <div className="max-w-[1100px] mx-auto px-24 py-48">
        <Breadcrumb />
        
        <h1 className="text-6xl md:text-6xl text-4xl font-medium leading-tight tracking-[-4px] mb-16 mt-32 text-center">
          Frequently Asked Questions
        </h1>
        <p className="text-lg md:text-xl font-light text-secondary tracking-[-0.5px] mb-48 text-center max-w-2xl mx-auto px-16">
          Got questions? We've got answers. If you can't find what you're looking for, feel free to contact us.
        </p>
        
        <div className="space-y-16 mb-64">
          {faqs.map((faq, index) => (
            <div 
              key={index}
              className="border border-gray-100 rounded-lg overflow-hidden"
            >
              <button
                className="w-full p-16 md:p-24 flex justify-between items-center bg-white hover:bg-gray-50 transition-colors"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <span className="font-medium text-left text-sm md:text-base">{faq.question}</span>
                <svg
                  className={`w-16 h-16 flex-shrink-0 ml-16 transform transition-transform ${
                    openIndex === index ? 'rotate-180' : ''
                  }`}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              
              <AnimatePresence>
                {openIndex === index && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="p-16 md:p-24 pt-0 text-secondary text-sm md:text-base border-t border-gray-100">
                      {faq.answer}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>

        {/* Bottom CTA */}
        <div className="text-center border-t border-gray-100 pt-48">
          <h2 className="text-3xl font-medium mb-16">Still Have Questions?</h2>
          <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-32 max-w-2xl mx-auto">
            Our team is here to help you get started with your social media journey.
          </p>
          <div className="flex justify-center gap-16">
            <Button variant="primary">Contact Support</Button>
            <Button variant="secondary">Schedule a Call</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ; 