import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Breadcrumb() {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  return (
    <div className="flex items-center gap-8 text-sm text-secondary">
      <Link to="/" className="hover:text-primary">Home</Link>
      {pathSegments.map((segment, index) => (
        <React.Fragment key={segment}>
          <span className="text-gray-300">/</span>
          <span className="capitalize">
            {segment}
          </span>
        </React.Fragment>
      ))}
    </div>
  );
}

export default Breadcrumb; 