import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Button from './Button';

function FAQ({ question, answer, isOpen, onToggle }) {
  return (
    <div className="border border-gray-100 rounded-lg overflow-hidden">
      <button
        onClick={onToggle}
        className="w-full p-16 md:p-24 flex justify-between items-center bg-white hover:bg-gray-50 transition-colors"
      >
        <span className="font-medium text-left text-sm md:text-base">{question}</span>
        <svg
          className={`w-16 h-16 flex-shrink-0 ml-16 transform transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="p-16 md:p-24 pt-4 text-secondary text-sm md:text-base border-t border-gray-100">
              {answer}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function HowItWorksModal({ isOpen, onClose, onOrderClick }) {
  const [openFAQ, setOpenFAQ] = useState(null);

  const faqs = [
    {
      question: "What types of content do you create?",
      answer: "Static posts, carousels and stories."
    },
    {
      question: "How fast will I receive my creatives?",
      answer: "Delivery times vary depending on the brief. Most static ads are delivered within 24 hours."
    },
    {
      question: "How many revisions do I get?",
      answer: "You may request unlimited revisions until you're happy with the final ad creative."
    },
    {
      question: "Can I cancel or change my plan?",
      answer: "Yes, no long-term contracts over here. Cancel or pause your plan at any time with no questions asked."
    }
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
          className="fixed inset-0 bg-black/40 backdrop-blur-sm z-50 flex items-start justify-center p-16 md:p-24 md:items-center"
        >
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="w-full max-w-2xl bg-white rounded-xl shadow-xl relative my-auto"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-16 right-16 text-gray-400 hover:text-gray-600 transition-colors p-8 rounded-full hover:bg-gray-50"
            >
              <svg className="w-24 h-24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>

            <div className="max-h-[85vh] overflow-y-auto">
              {/* Header Section */}
              <div className="text-center p-24 md:p-48 pb-32">
                <h2 className="text-4xl md:text-5xl font-normal mb-16 tracking-[-2px]" style={{ fontFamily: 'var(--font-instrument)' }}>
                  Create Content at Scale
                </h2>
                <p className="text-md text-black font-normal tracking-[-0.5px] max-w-xl mx-auto">
                  Get high-quality social media creatives delivered consistently, without the hassle of managing an in-house team.
                </p>
              </div>

              {/* How It Works */}
              <div className="px-24 md:px-48 pb-32">
                <div className="bg-gray-50 rounded-xl p-24 md:p-32">
                  <h3 className="text-xl font-medium mb-16">How It Works</h3>
                  <p className="text-md text-black font-normal tracking-[-0.5px] mb-8">
                    If we think we'd make a good team, we'll add you to your own Trello board where you can sumbit ad requests and track progress on current ads.
                  </p>
                  <p className="text-md text-black font-normal tracking-[-0.5px] mb-8">
                    We'll work on one request at a time, so you'll always have a fresh batch of creatives to post. Each creative will be delivered within 24 hours, depenidng on the brief.
                  </p>
                </div>
              </div>

              {/* FAQ Section */}
              <div className="px-24 md:px-48 pb-32">
                <h3 className="text-xl font-medium mb-24">Common Questions</h3>
                <div className="space-y-16">
                  {faqs.map((faq, index) => (
                    <FAQ
                      key={index}
                      question={faq.question}
                      answer={faq.answer}
                      isOpen={openFAQ === index}
                      onToggle={() => setOpenFAQ(openFAQ === index ? null : index)}
                    />
                  ))}
                </div>
              </div>

              {/* CTA Section */}
              <div className="text-center border-t border-gray-100 p-24 md:p-32 bg-gray-50/50">
                <p className="text-xl text-black font-medium tracking-[-0.5px] mb-16">Ready to take advantage of this offer?</p>
                <Button 
                  variant="primary" 
                  onClick={() => {
                    onClose();
                    onOrderClick();
                  }}
                >
                  Book a Call
                </Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default HowItWorksModal; 