import React from 'react';
import SimplePage from '../components/SimplePage';
import Button from '../components/Button';

function Contact() {
  const content = (
    <>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-48">
        Have questions? We're here to help. Choose the best way to reach us below.
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-32 mb-48">
        <div className="bg-white rounded-lg p-32 shadow-sm border border-gray-100">
          <h2 className="text-2xl font-medium mb-16">General Inquiries</h2>
          <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
            For general questions about our services, pricing, or how we work.
          </p>
          <Button variant="primary" className="w-full">
            Send Message
          </Button>
        </div>

        <div className="bg-white rounded-lg p-32 shadow-sm border border-gray-100">
          <h2 className="text-2xl font-medium mb-16">Support</h2>
          <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
            Need help with an existing order or have technical questions?
          </p>
          <Button variant="primary" className="w-full">
            Contact Support
          </Button>
        </div>
      </div>

      <div className="text-center border-t border-gray-100 pt-48">
        <h2 className="text-2xl font-medium mb-16">Other Ways to Connect</h2>
        <div className="flex flex-col gap-16">
          <p className="text-xl font-light text-secondary tracking-[-0.5px]">
            Email: hello@socialmate.com
          </p>
          <p className="text-xl font-light text-secondary tracking-[-0.5px]">
            Hours: Monday - Friday, 9am - 5pm EST
          </p>
          <p className="text-xl font-light text-secondary tracking-[-0.5px]">
            Response Time: Within 24 hours
          </p>
        </div>
      </div>
    </>
  );

  return (
    <SimplePage
      title="Contact Us"
      content={content}
    />
  );
}

export default Contact; 