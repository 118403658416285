import React from 'react';
import SimplePage from '../components/SimplePage';

function About() {
  const content = (
    <>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        G'day. We're socialmate. we come from a ladn downunder. We're a team of creative professionals dedicated to making social media content creation simple and efficient for businesses of all sizes.
      </p>
      
      <p className="text-xl font-light text-secondary tracking-[-0.5px]">
        Our mission is to streamline the creative process, delivering high-quality social media assets that help brands stand out in the digital space. We believe in keeping things simple, transparent, and effective.
      </p>
    </>
  );

  return (
    <SimplePage
      title="About Us"
      content={content}
      ctaText="Get Started Today"
      onCtaClick={() => {}}
    />
  );
}

export default About; 