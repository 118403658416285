import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

function OrderModal({ isOpen, onClose }) {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi({"namespace":"30min"});
      cal("ui", {
        "hideEventTypeDetails": false,
        "layout": "month_view",
        "styles": {
          "branding": {
            "brandColor": "#1877F2"
          }
        }
      });
    })();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
          className="fixed inset-0 bg-black/40 backdrop-blur-sm z-50 flex items-center justify-center md:p-24"
        >
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="w-full h-[100dvh] md:h-auto max-h-[100dvh] md:max-h-none md:max-w-4xl bg-white rounded-none md:rounded-xl shadow-xl relative flex flex-col overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={onClose}
              className="absolute top-16 right-16 text-gray-400 hover:text-gray-600 transition-colors p-8 rounded-full hover:bg-gray-50 z-10"
            >
              <svg className="w-24 h-24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>

            <div className="p-24 md:p-32 pb-16 text-center flex-shrink-0">
              <h2 className="text-4xl font-normal mb-8 tracking-[-2px]" style={{ fontFamily: 'var(--font-instrument)' }}>
                Book Your Call
              </h2>
              <p className="text-md text-black font-normal tracking-[-0.5px] max-w-xl mx-auto">
                Choose a time that works best for you. We'll discuss your needs and see if we're a good fit.
              </p>
            </div>

            <div className="flex-1 min-h-0 px-16 md:px-32 pb-32">
              <Cal 
                namespace="30min"
                calLink="socialmate/30min"
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "500px",
                  overflow: "auto"
                }}
                config={{
                  "layout": "month_view",
                  "hideEventTypeDetails": false,
                  "styles": {
                    "branding": {
                      "brandColor": "#1877F2"
                    }
                  }
                }}
              />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default OrderModal; 