import React from 'react';
import SimplePage from '../components/SimplePage';

function Terms() {
  const content = (
    <>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        Last updated: March 2024
      </p>
      
      <h2 className="text-2xl font-medium mb-16 mt-32">Service Agreement</h2>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        By accessing our services, you agree to these terms and conditions. We reserve the right to modify these terms at any time, and your continued use of our services constitutes acceptance of any changes.
      </p>

      <h2 className="text-2xl font-medium mb-16 mt-32">Content Ownership</h2>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        Upon full payment, you receive ownership rights to the delivered content. However, we retain the right to use the content in our portfolio and marketing materials unless explicitly agreed otherwise in writing.
      </p>

      <h2 className="text-2xl font-medium mb-16 mt-32">Refund Policy</h2>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        We offer revisions to ensure your satisfaction. If we cannot meet your requirements after reasonable revision attempts, we will provide a full refund within 30 days of the original purchase.
      </p>

      <h2 className="text-2xl font-medium mb-16 mt-32">Service Limitations</h2>
      <p className="text-xl font-light text-secondary tracking-[-0.5px] mb-24">
        While we strive to deliver all content within the specified timeframes, delivery times may vary based on complexity and revision requests. We are not liable for delays caused by factors outside our control.
      </p>
    </>
  );

  return (
    <SimplePage
      title="Terms of Service"
      content={content}
    />
  );
}

export default Terms; 