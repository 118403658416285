import React from 'react';
import { motion } from 'framer-motion';

function CarouselItem({ brand, isAnyHovered, isHovered, onHoverStart, onHoverEnd }) {
  return (
    <motion.div
      className="flex-none w-[300px] relative cursor-pointer"
      initial={{ rotate: 0 }}
      animate={{
        filter: isAnyHovered && !isHovered ? 'blur(2px)' : 'blur(0px)',
        scale: isAnyHovered && !isHovered ? 0.98 : 1,
        opacity: isAnyHovered && !isHovered ? 0.85 : 1,
        transition: {
          filter: { duration: 0.3 },
          scale: { duration: 0.3 },
          opacity: { duration: 0.3 }
        }
      }}
      whileHover={{
        scale: 1.01,
        rotate: [-0.3, 0.3],
        y: -5,
        filter: 'blur(0px)',
        opacity: 1,
        zIndex: 10,
        transition: {
          rotate: {
            duration: 0.3,
            ease: [0.6, 0.05, -0.01, 0.9],
          },
          scale: {
            type: "spring",
            stiffness: 200,
            damping: 25
          }
        }
      }}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
    >
      {/* Main Image Container */}
      <motion.div
        className="w-[300px] h-[300px] rounded-lg overflow-hidden relative mb-16"
        whileHover={{
          boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
        }}
      >
        {/* Gradient overlay that appears on hover */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-tr from-black/[0.07] to-white/[0.07] z-10"
          initial={{ opacity: 0 }}
          whileHover={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
        />
        
        {/* Main image */}
        <img 
          src={brand.image} 
          alt={`${brand.name} advertisement`}
          className="w-full h-full object-cover"
        />

        {/* Border effect */}
        <motion.div
          className="absolute inset-0 rounded-lg"
          initial={{ opacity: 0 }}
          whileHover={{ 
            opacity: 1,
            transition: {
              duration: 0.2
            }
          }}
          style={{
            boxShadow: "inset 0 0 0 1px rgba(0,0,0,0.08)"
          }}
        />
      </motion.div>

      {/* Brand Information */}
      <div className="flex items-center gap-8 px-8">
        <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-50">
          <img 
            src={brand.logo} 
            alt={`${brand.name} logo`}
            className="w-full h-full object-cover"
          />
        </div>
        <span className="text-sm text-gray-400">{brand.name}</span>
      </div>
    </motion.div>
  );
}

export default CarouselItem; 