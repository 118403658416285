import React from 'react';
import SimplePage from '../components/SimplePage';
import Button from '../components/Button';

function HowItWorks() {
  const content = (
    <>
      <div className="max-w-2xl mx-auto text-center mb-48 md:mb-64 px-16">
        <p className="text-lg md:text-xl font-light text-secondary tracking-[-0.5px] mb-24">
          Our process is designed to be simple and efficient, ensuring you get high-quality social media content without the complexity.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-32 md:gap-48 mb-48 md:mb-64">
        <div className="p-16 md:p-0">
          <h2 className="text-xl md:text-2xl font-medium mb-12 md:mb-16">The Process</h2>
          <p className="text-base md:text-xl font-light text-secondary tracking-[-0.5px]">
            Choose your monthly content volume and subscription tier that matches your needs. After completing a brief questionnaire about your brand and preferences, our team starts creating your content right away.
          </p>
        </div>
        
        <div className="p-16 md:p-0">
          <h2 className="text-xl md:text-2xl font-medium mb-12 md:mb-16">Delivery & Revisions</h2>
          <p className="text-base md:text-xl font-light text-secondary tracking-[-0.5px]">
            You'll receive your first batch within 72 hours, followed by regular deliveries based on your plan. Request revisions anytime to ensure the content perfectly matches your vision. We offer unlimited revisions on our Professional and Enterprise plans.
          </p>
        </div>
      </div>

      {/* Bottom CTA */}
      <div className="text-center border-t border-gray-100 pt-48">
        <h2 className="text-2xl md:text-3xl font-medium mb-12 md:mb-16">Ready to Get Started?</h2>
        <p className="text-lg md:text-xl font-light text-secondary tracking-[-0.5px] mb-24 md:mb-32 px-16">
          Transform your social media presence with our expert team.
        </p>
        <div className="flex flex-col md:flex-row justify-center gap-16">
          <Button variant="primary">Start Creating Content</Button>
          <Button variant="secondary">View Pricing Plans</Button>
        </div>
      </div>
    </>
  );

  return (
    <SimplePage
      title="How It Works"
      content={content}
    />
  );
}

export default HowItWorks; 