import React from 'react';
import { motion } from 'framer-motion';

function Button({ children, variant = 'primary', className = '', ...props }) {
  const baseStyles = `
    relative px-24 py-8 rounded-md font-medium transition-all duration-200
    inline-flex items-center justify-center min-h-[40px] text-sm
    before:absolute before:inset-0 before:rounded-md before:transition-all before:duration-200
    after:absolute after:inset-0 after:rounded-md after:transition-all after:duration-200
    disabled:opacity-50 disabled:cursor-not-allowed
    active:scale-[0.98] active:duration-100
    backdrop-blur-[2px]
  `;

  const variants = {
    primary: `
      bg-gradient-to-b from-[#1877F2] via-[#1877F2] to-[#1465cc]
      text-white
      shadow-[0_2px_1px_-1px_rgba(0,0,0,0.1),0_1px_1px_0_rgba(0,0,0,0.07),0_1px_3px_0_rgba(0,0,0,0.1),inset_0_1px_2px_rgba(255,255,255,0.4)]
      before:shadow-[0_1px_2px_rgba(255,255,255,0.4)]
      before:opacity-100
      after:shadow-[inset_0_-2px_4px_rgba(0,0,0,0.2)]
      after:opacity-100
      hover:from-[#1877F2] hover:via-[#1877F2] hover:to-[#1877F2]
      hover:shadow-[0_4px_6px_-2px_rgba(24,119,242,0.2),0_2px_4px_-1px_rgba(24,119,242,0.1),inset_0_1px_1px_rgba(255,255,255,0.4)]
      hover:before:opacity-50
      hover:after:opacity-50
      active:shadow-[0_1px_2px_rgba(24,119,242,0.2),inset_0_1px_5px_rgba(0,0,0,0.2)]
      active:before:opacity-25
      active:after:opacity-25
      active:bg-[#1465cc]
    `,
    secondary: `
      bg-gradient-to-b from-[#fafafa] via-white to-[#f8f8f8]
      text-black
      shadow-[0_2px_1px_-1px_rgba(0,0,0,0.1),0_1px_1px_0_rgba(0,0,0,0.07),0_1px_3px_0_rgba(0,0,0,0.1),inset_0_1px_2px_rgba(255,255,255,0.4)]
      border border-[rgba(0,0,0,0.08)]
      before:shadow-[0_1px_2px_rgba(255,255,255,0.8)]
      before:opacity-100
      after:shadow-[inset_0_-2px_4px_rgba(0,0,0,0.05)]
      after:opacity-100
      hover:from-white hover:via-white hover:to-[#fafafa]
      hover:shadow-[0_4px_6px_-2px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06),inset_0_1px_1px_rgba(255,255,255,0.4)]
      hover:border-[rgba(0,0,0,0.12)]
      hover:before:opacity-50
      hover:after:opacity-50
      active:shadow-[0_1px_2px_rgba(0,0,0,0.1),inset_0_1px_5px_rgba(0,0,0,0.1)]
      active:before:opacity-25
      active:after:opacity-25
      active:bg-[#f5f5f5]
    `
  };

  return (
    <motion.button 
      className={`${baseStyles} ${variants[variant]} ${className}`}
      whileHover={{ y: -1 }}
      whileTap={{ y: 1 }}
      {...props}
    >
      {children}
    </motion.button>
  );
}

export default Button; 